Draw.loadPlugin(function(ui) {
    const DataObject = window.parent.DataObject;
    const DialogProps = window.parent.DialogProps;
    const CreateDialogContent = window.parent.CreateDialogContent;
    const customLibraries = window.parent.customLibraries;
    const isEnabled = customLibraries != undefined;

    if (!DataObject) {
        // $t('xxxxxx') $t('yyyyyyy')
        throw "parent window must expose window.DataObject";
    }


    const ORIGINAL_STYLE = "omega-original-style";
    const OMEGA_SCALE = "omega-scale";
    const OMEGA_VIEW = "omega-view";
    const OMEGA_COLUMN = "omega-column";
    const OMEGA_LIMIT = "omega-limit";
    const OMEGA_WHERE = "omega-where";
    const OMEGA_PREV_PARAMS = "omega-prev-params";
    const OMEGA_LOOKUP_ = "omega-lookup-"
    const OMEGA_ = "omega-";

    const svgString = "data:image/svg+xml,";

    function loadLibrary(url, title) {
        mxUtils.get(url, function(response) {
            if (200 <= response.getStatus() && 299 >= response.getStatus()) {
                try {
                    ui.loadLibrary(new UrlLibrary(this,response.getText(),title));
                } catch (err) {
                    ui.handleError(err, mxResources.get("errorLoadingFile"))
                }
            } else
                ui.handleError(null, mxResources.get("errorLoadingFile"))
        }, function() {
            ui.handleError(null, mxResources.get("errorLoadingFile"))
        });
    }

    for (let lib of customLibraries ?? []) {
        loadLibrary(lib.url, lib.title);
    }

    function base64EncodeUtf8(str) {
        let arr = (new TextEncoder('UTF-8')).encode(str);
        let res = "";
        for (let i = 0; i < arr.length; i++) {
            res += String.fromCharCode(arr[i]);
        }

        return btoa(res);
    }

    mxCell.prototype.updateCellProperty = function(property, value) {
        if (!this.getValue()) {
            let elem = mxUtils.createXmlDocument().createElement("object");
            elem.setAttribute(property, value);
            this.setValue(elem);
        } else {
            this.setAttribute(property, value);
        }
    }

    function semicolonSeparatedValuesToObj(str) {
        return str.split(';').reduce((acc, cur) => {
                let arr = cur.split('=');
                acc[arr[0]] = arr[1];
                return acc;
            }, {});
    }

    function getWhereClauseColumns(whereClause) {
        if (!whereClause) {
            return [];
        }
        return [...whereClause.matchAll(/{([^}]+)}/g)].map(m => m[1]);
    }


    function getCellImageXml(cell) {
        const parser = new DOMParser();
        let style = cell.getAttribute(ORIGINAL_STYLE) || cell.style;
        if (!style)
        {
            return null; 
        }

        let styleData = semicolonSeparatedValuesToObj(style);
        let image = styleData["image"];
        if (!image?.startsWith(svgString)) {
            return null;
        }

        // Save original style
        if (!cell.getAttribute(ORIGINAL_STYLE)?.length) {
            cell.updateCellProperty(ORIGINAL_STYLE, style);
        }

        let text = atob(image.substr(svgString.length));
        return parser.parseFromString(text, "text/xml");
    }

    function getSvgRequiredParams(xml) {
        return [...xml.getElementsByTagName("svg")]
            .filter(e => e.getAttribute(OMEGA_WHERE))
            .reduce((acc, cur) => {
                getWhereClauseColumns(cur.getAttribute(OMEGA_WHERE)).forEach(c => acc.add(c));
                return acc;
            }, new Set());
    }

    function acquireDataObject(viewName, fields, limit) {
        return new DataObject({
                id: `dsDrawIoDynamic${crypto.randomUUID()}`,
                viewName: viewName,
                distinctRows: true,
                maxRecords: limit,
                fields: fields.map(f => { return {name: f} })
            });
    }

    function getSvgParamsValues(cell, requiredParams) {
        let res = new Map();

        for (let param of requiredParams) {
            let val = cell.getAttribute(OMEGA_ + param)
            if (val == undefined) {
                //Parameter not provided
                continue;
            }

            //paramsString += 
            res.set(param, val);
        }

        return res;
    }

    function updateCellStyle (cell, xml) {
        updateScale(xml.documentElement);
        let svgText = serializer.serializeToString(xml);
        cell.geometry.height = +xml.documentElement.getAttribute("height") || 0;
        cell.geometry.width = +xml.documentElement.getAttribute("width") || 0;
        graph.setCellStyles("image", svgString + base64EncodeUtf8(svgText), [cell]);
    }



    class OmegaWindow {
        constructor(ui, x, y, w, h) {
            this.ui = ui;

            this.div = document.createElement('div');
            this.div.style.overflow = 'hidden';
            this.div.style.padding = '12px 8px 12px 8px';
            this.div.style.height = 'auto';

            CreateDialogContent(this.div);

            this.window = new mxWindow("Omega", this.div, x, y, w, h, true, true);
            this.window.setVisible(true);
            this.window.destroyOnClose = false;
        }

        getInput(cell, svg, param, initialValue) {
            let inputData = { 
                title: param,
                value: initialValue,
                callback: (val, displayVal) => {
                    if (!val) {
                        cell.getValue()?.removeAttribute(OMEGA_ + param);
                    } else {
                        cell.setAttribute(OMEGA_ + param, val);
                    }

                    cell.setAttribute(OMEGA_ + param + '_display', displayVal ?? '');

                    updateOmegaComponent(cell);
                }
            };

            let lookupVal = svg.getAttribute(OMEGA_LOOKUP_ + param);

            if (!lookupVal) {
                // default to input field if missing lookup data
                return inputData;
            }

            const [view, resultColumn, displayColumn] = lookupVal.split(';');

            if (!view || !resultColumn || !displayColumn) {
                throw `invalid ${OMEGA_LOOKUP_}${param}`;
            }

            let dataObject = acquireDataObject(view, [resultColumn, displayColumn], 100);

            let columnDef = [
                {
                    field: displayColumn
                }
            ];

            return {
                ...inputData,
                dataobject: dataObject,
                columnDef: columnDef,
                displayColumn: displayColumn,
                resultColumn: resultColumn,
            };;
        }

        setCell(cell) {
            DialogProps.inputs = [];

            if (!cell) {
                return;
            }

            let xml = getCellImageXml(cell);
            if (!xml) {
                return;
            }

            let svg = xml.documentElement;

            let requiredParams = getSvgRequiredParams(xml);  
            let params = getSvgParamsValues(cell, requiredParams);
            
            for (let param of requiredParams) {
                let displayValue = cell.getAttribute(OMEGA_ + param + '_display') || params.get(param);
                DialogProps.inputs.push(
                    this.getInput(cell, svg, param, displayValue)
                );
            }
        }
    }

	var graph = ui.editor.graph;
	
    const serializer = new XMLSerializer();
	
    function getElementsOfType(svg, type) {
        return [...svg.getElementsByTagName(type)]
                            .reduce((res, elem) => {
                                // only get elements from current svg (not child svgs)
                                let parent = elem.parentElement;
                                while (parent !== svg && !(parent instanceof SVGSVGElement)) {
                                    parent = parent.parentElement;
                                }
                                if (parent !== svg) {
                                    return res;
                                }
                                if (elem.attributes[OMEGA_COLUMN]?.value || type === 'svg') {
                                    res.push(elem);
                                }
                                return res;}
                            , []);
    }

    function updateScale(svg) {
        let scale = svg.getAttribute(OMEGA_SCALE);
 
        if (!scale) {
            return;
        }

        let position;

        if (scale == "height") {
            position = "y";
        } else if (scale == "width") {
            position = "x";
        }
        else {
            throw "omega-scale must be height, width or not supplied"
        }

        let newSize = [...svg.childNodes].reduce((prev, cur) => {
            let height;
            if (cur instanceof SVGTextElement) {
                height = parseInt(cur.getAttribute("font-size"));
            }
            else if (cur[scale]?.baseVal?.unitType === 1 && cur[position]?.baseVal?.unitType === 1) {
                height = cur[scale].baseVal.value;
            }

            let pos;
            if (cur[position]?.baseVal?.unitType === 1) {
                pos = cur[position].baseVal.value;
            } else if (cur[position]?.baseVal?.[0]?.unitType === 1) {
                pos = cur[position].baseVal[0].value;
            }

            if (height == undefined || pos == undefined) {
                return prev;
            }

            return Math.max(prev, pos + height);
        }, 0);

        svg.setAttribute(scale, newSize);
    }
    
	function updateOmegaComponent(cell)
	{
		let xml = getCellImageXml(cell);
        if (!xml) {
            return;
        }

        let requiredParams = getSvgRequiredParams(xml);  
        let params = getSvgParamsValues(cell, requiredParams);

        if (params.size != requiredParams.size) {
            updateCellStyle(cell, xml);
            // not all parameters provided
            return;
        }

        let paramsString = "";
        for (const [key, value] of params.entries()) {
            paramsString += `${key}=${value};`;
        }

        if (cell.getAttribute(OMEGA_PREV_PARAMS) === paramsString) {
            // No change
            return;
        }

        cell.updateCellProperty(OMEGA_PREV_PARAMS, paramsString);

        var tasksInProgress = 0;
        function addCountTasksInProgress() {
            tasksInProgress++;
        };

        function taskFinished() {
            tasksInProgress--;
            if (tasksInProgress <= 0) {
                updateCellStyle(cell, xml);
            }
        };

		updateSvg(xml.documentElement);

        function applyValuesToSvg(svg, row) {
            let textElements = getElementsOfType(svg, 'text');
            let anchorElements = getElementsOfType(svg, 'a');
            let imageElements = getElementsOfType(svg, 'image');

            for (let elem of textElements) {
                let newValue = row[elem.getAttribute(OMEGA_COLUMN)];
                elem.textContent = newValue;
            }

            for (let elem of anchorElements) {
                let newValue = row[elem.getAttribute(OMEGA_COLUMN)];
                elem.setAttribute("href", newValue);
            }

            for (let elem of imageElements) {
                let url = row[elem.getAttribute(OMEGA_COLUMN)];
                addCountTasksInProgress();
                fetch(url)
                    .then( response => response.blob() )
                    .then( blob =>{
                        var reader = new FileReader() ;
                        reader.onload = function(){ 
                            elem.setAttribute("href", this.result);
                            taskFinished();
                        };
                        reader.readAsDataURL(blob) ;
                    }) ;
            }
        }
		
        async function updateSvg(svg) {
            let svgElements = getElementsOfType(svg, 'svg');

            for (let innerSvg of svgElements) {
                updateSvg(innerSvg);
            }

            let viewName = svg.getAttribute(OMEGA_VIEW);
            
            if (viewName == null) {
                return;
            }

            let limit = svg.getAttribute(OMEGA_LIMIT) ?? 1;

            if (limit != 1 && svg === xml.documentElement) {
                throw "Root svg element must have limit = 1"
            }

            addCountTasksInProgress();

            let whereClause = svg.getAttribute(OMEGA_WHERE);
            let whereColumns = getWhereClauseColumns(whereClause);

            for (let whereColumn of whereColumns) {
                let val = cell.getAttribute(OMEGA_ + whereColumn);
                if (val == undefined) {
                    // where clause column value not yet specified
                    return;
                }

                whereClause = whereClause.replaceAll(`{${whereColumn}}`, val);
            }

            let columns = new Set();
            
            let anchorElements = getElementsOfType(svg, 'a');
            let textElements = getElementsOfType(svg, 'text');
            let imageElements = getElementsOfType(svg, 'image');

            for (let elem of [...textElements, ...imageElements, ...anchorElements]) {
                columns.add(elem.getAttribute(OMEGA_COLUMN));
            }

            let dataObject = acquireDataObject(viewName, [...columns], limit);

            dataObject.recordSource.whereClause = whereClause;

            let results = await dataObject.load();

            if (!results) {
                throw "Failed to load data object";
            }

            if (!results.length) {
                if (svg === xml.documentElement) {
                    throw "No result from data object on root element";
                }
                svg.parentNode.removeChild(svg);
            } else {
                updateScale(svg);
                
                applyValuesToSvg(svg, results[0]);
                for (let i = 1, prevSvg = svg; i < results.length; i++) {
                    let newSvg = prevSvg.cloneNode(true);
                    let prevY = +(prevSvg.getAttribute("y") ?? 0);
                    let prevHeight = +(prevSvg.getAttribute("height") ?? 0);
                    newSvg.setAttribute("y", prevY + prevHeight);
                    // insertAfter
                    prevSvg.parentNode.insertBefore(newSvg, prevSvg.nextSibling);

                    applyValuesToSvg(newSvg, results[i]);
                    prevSvg = newSvg;
                }
            }

            taskFinished();
        }
	};
	
	function refresh()
	{
        let cells = graph.model.cells;

		for (let id in cells)
		{
			updateOmegaComponent(cells[id]);
		}
	};
    if (isEnabled) {
        graph.addListener(mxEvent.SIZE, refresh);
        graph.selectionModel.addListener(mxEvent.CHANGE, function(model) {
            ui.omegaWindow.setCell(model.cells?.[0]);       
        });
        refresh();

        ui.omegaWindow = new OmegaWindow(ui, 200, 200, 200, 200);
    }
});

